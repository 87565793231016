import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { getToken } from '@/utils/auth'
Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    name:'Home',
    component:() =>import('@/views/verify/index')
  },
  {
    path:'/verify',
    name:'Verify',
    component:() =>import('@/views/home/home')
  },
  {
    path:'/login',
    name:'login',
    component:() =>import('@/views/login/login')
  },
  {
    path:'/face',
    name:'face',
    component:() =>import('@/views/facelogin/index')
  },
  {
    path:'/update',
    name:'update',
    component:() =>import('@/views/changeface/changeface')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  // determine whether the user has logged in

  console.log(to)
  const hasToken = getToken()
  
  if(hasToken){
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      next();
    }
  }else{
    
    if(to.path=='/login'){
      //重新触发的时候会进入到这里,因为to.path已经变为/login了，然后直接跳转到登录页
      next()
   }else{
      //第一次会走到这个位置，然后重新触发路由钩子函数beforeEach,
      //当重新触发钩子函数的时候这时的to.path已经变为/login
      next(`/login?redirect=${to.path}`)
  }
}
})
export default router
