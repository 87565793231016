import Vue from 'vue'
import Vuex from 'vuex'
import { getToken, setToken, removeToken } from '@/utils/auth'
import {reqLogin,reqAddFace,reqUserInfo } from "@/api/verify"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:getToken(),
    userId:window.localStorage.getItem('userId'),
    userInfo:window.localStorage.getItem('userInfo')? JSON.parse(window.localStorage.getItem('userInfo')) : '',
    groupId:'1',
    faceInfo:window.localStorage.getItem('faceInfo')? window.localStorage.getItem('faceInfo') : '',
    device:'',
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USERID: (state, userId) => {
      state.userId = userId
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
    SET_FACEINFO: (state, faceInfo) => {
      state.faceInfo = faceInfo
    },
    SET_DEVICE: (state, device) => {
      state.device = device
    },
    REMOVE_TOKEN: (state) => {
      state.token = ''
    },
  },
  actions: {
    // remove token
    resetToken({ commit }) {
      return new Promise(resolve => {
        removeToken() // must remove  token  first
        commit('SET_USERID','');
        window.localStorage.removeItem('userId')
        resolve();
      })
    },
    login({commit},loginForm) {
      return new Promise((resolve,reject) => {
        reqLogin(loginForm).then(response => {
          if(response.code !=0) {
            reject(response)
            return;
          }
          console.log('response',response)
          let { token, userId, userInfo} = response.tokenEntity

          commit('SET_TOKEN',token)
          commit('SET_USERID', userId)
          commit('SET_USERINFO',userInfo[0])
          window.localStorage.setItem('userInfo', JSON.stringify(userInfo[0]));
          window.localStorage.setItem('userId', userId);
          setToken(token)
          resolve(token);
  
        }).catch((err) => {
          reject(err)
        })
      })
    }
  }
})
