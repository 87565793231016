import request from "@/utils/request"
import QS from 'qs';

// 检查人脸是否规范
export function checkFace(data) {
  return request({
    url: '/api/user/checkFace',
    method: 'post',
    Headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

// 人脸录入
export function reqAddFace(data) {
  return request({
    url: '/api/user/faceAdd',
    method: 'post',
    Headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
// 人脸判断
export function reqVerifyFace(data) {
  return request({
    url: '/api/user/serchFace',
    method: 'post',
    Headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
// 登录接口
export function reqLogin(data) {
  return request({
    url: '/api/user/tblogin',
    method: 'post',
    Headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
// 获取用户信息
export function reqUserInfo(data) {
  return request({
    url: '/api/user/myInformation',
    method: 'post',
    Headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
//识别成功，拉取用户信息
export function reqFindUserInfo(data) {
  return request({
    url: '/api/user/findUser',
    Headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

//人脸是否存在
export function reqExistFace(data) {
  return request({
    url: '/api/user/existFace',
    method: 'post',
    Headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}