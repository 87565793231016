import axios from 'axios'; // 引入axios
import {
    Toast
} from 'vant';
import store from '@/store/index';
import router from '@/router/index'

// 创建实例
const instance = axios.create();

//设置请求超时
instance.defaults.timeout = 10000;

// 设置post默认的请求的content-type
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// 环境的切换
if (process.env.NODE_ENV == 'development') {
  // instance.defaults.baseURL = '';

} else if (process.env.NODE_ENV == 'production') {
  
}

// 请求拦截
instance.interceptors.request.use(
    config => {
        // 每次发送请求之前判断vuex中是否存在token        
        // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断 
        // const token = JSON.stringify(store.state.user.token);
        // token && (config.headers.Authorization = token);
        return config;
    },
    error => {
        return Promise.error(error);
})

// 响应的拦截
instance.interceptors.response.use(
    response => {
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据     
        // 否则的话抛出错误
        // console.log('response',response)
        if (response.status === 200) {
            return Promise.resolve(response.data);
        } else {

            // handleErrorStatus(response.status);
            return Promise.reject(response);
        }
    },
    error => {
            if (error.status) {
                // handleErrorStatus(error.status)
                return Promise.reject(error);
            }
        })

/** 
 * 请求失败后的错误统一处理 
 * @param {Number} status 请求失败的状态码
 */
function handleErrorStatus(status) {

    switch (status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径              
        case 401:
            router.replace({
                path: '/login',
                query: {
                    redirect: router.currentRoute.fullPath
                }
            });
            break;

            // 403 token过期
            // 登录过期对用户进行提示
            // 清除本地token和清空vuex中token对象
            // 跳转登录页面                
        case 403:
            Toast({
                message: '登录过期，请重新登录',
                duration: 1000,
                forbidClick: true
            });
            // 清除token
            window.localStorage.removeItem('token');
            store.commit('REMOVE_TOKEN')
            // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面 
            setTimeout(() => {
                router.replace({
                    path: '/login',
                    query: {
                        redirect: router.currentRoute.fullPath
                    }
                });
            }, 1000);
            break;

            // 404请求不存在
        case 404:
            Toast({
                message: '网络请求不存在',
                duration: 1500,
                forbidClick: true
            });
            break;
            // 其他错误，直接抛出错误提示
        default:
            Toast({
                message: error.response.data.message,
                duration: 1500,
                forbidClick: true
            });
    }
}


export default instance;

